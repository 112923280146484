import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')))

const demandDataRoute = [
    {
        path: '/demand-data',
        element: <AppIndex name={'demand-data'} title={'Demand Data'} />,
        // auth: authRoles.editor,
    },
]

export default demandDataRoute
