import { Api } from '../api'

export const GET_SALES = 'GET_SALES'
export const getSales = (
    form = { page: 1, pageLimit: 10, statusOrder: 'Presales' }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/sales`, {
                params: form,
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_SALES,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
