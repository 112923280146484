import {
    GET_PRESALES_LIST,
    GET_PRESALES_BY_ID,
    GET_ODP_MARKER,
    GET_SELECT_ODP,
    GET_SELECT_REGIONAL,
    GET_SELECT_WITEL,
    GET_SELECT_DATEL,
    GET_SELECT_TEKNISI,
    GET_SELECT_MITRA,
    GET_SELECT_STO_BY_DATEL,
    GET_SELECT_ODP_BY_STO,
    GET_SALES_LIST,
    GET_SALES_BY_ID,
    GET_SALES_LIST_STATUS,
    GET_ODP_EXISTING_BY_STATUS,
    
} from '../actions/PreSalesActions'

const initialState = {
    presalesList: [],
    presalesItem: [],
    odpMarker: [],
    odpItem: [],
    regionalItem: [],
    validasiOrder: [],
    validasiOrderItem: [],
    validasiOrderItemByStatus:[],
    witelItem: [],
    datelItem: [],
    stoItem: [],
    odpExistingStatus:[],
    odpBySTOItem: [],
    teknisiList: [],
    mitraList: [],
    loading: true,
}

const PresalesReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PRESALES_LIST: {
            return {
                ...state,
                presalesList: action.data,
                loading: false,
            }
        }
        case GET_SALES_LIST: {
            return {
                ...state,
                validasiOrder: action.data,
                loading: false,
            }
        }
        case GET_SALES_LIST_STATUS: {
            return {
                ...state,
                validasiOrderItemByStatus: action.data,
                loading: false,
            }
        }
        case GET_ODP_MARKER: {
            return {
                ...state,
                odpMarker: action.data,
                loading: false,
            }
        }
        case GET_ODP_EXISTING_BY_STATUS: {
            return {
                ...state,
                odpExistingStatus: action.data,
                loading: false,
            }
        }
        case GET_SELECT_ODP: {
            return {
                ...state,
                odpItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_TEKNISI: {
            return {
                ...state,
                teknisiList: action.data,
                loading: false,
            }
        }
        case GET_SELECT_MITRA: {
            return {
                ...state,
                mitraList: action.data,
                loading: false,
            }
        }
        case GET_SELECT_REGIONAL: {
            return {
                ...state,
                regionalItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_WITEL: {
            return {
                ...state,
                witelItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_DATEL: {
            return {
                ...state,
                datelItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_STO_BY_DATEL: {
            return {
                ...state,
                stoItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_ODP_BY_STO: {
            return {
                ...state,
                odpBySTOItem: action.data,
                loading: false,
            }
        }
        case GET_PRESALES_BY_ID: {
            return {
                ...state,
                presalesItem: action.data,
                loading: false,
            }
        }
        case GET_SALES_BY_ID: {
            return {
                ...state,
                validasiOrderItem: action.data,
                loading: false,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default PresalesReducer
