import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const LogPenggunaan = Loadable(lazy(() => import('./menu')))

const logPenggunaan = [
    {
        path: '/Log-penggunaan',
        element: <LogPenggunaan />,
        // auth: authRoles.editor,
    },
]

export default logPenggunaan
