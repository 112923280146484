import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    TablePagination,
    Button, Grid, TextField, List, FormControlLabel, Checkbox, Select, MenuItem
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import React, { useState, useEffect } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { useLocation, useNavigate } from 'react-router-dom'
import { getSelectStoByProjectId, getSelectStatusByJenisProject, getSelectProject } from '../../../redux/actions/PlanningActions'
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Api } from '../../../redux/api'
import { toast } from 'react-toastify';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const TextInput = styled(TextField)(() => ({
    width: '100%',
    marginBottom: '16px',
}))

const Heading = styled('h5')(({ theme }) => ({
    margin: 0,
    marginTop: '4px',
    marginBottom: '10px',
    fontWeight: '800',
    fontSize: '18px',
    color: theme.palette.primary.main,
}))

const Label = styled('p')(({ theme }) => ({
    margin: 0,
    marginTop: '4px',
    fontSize: '12px',
    color: theme.palette.textLight,
    textAlign: 'left'
}))

const EditLop = (props) => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const location = useLocation()
    const selectedItem = location.state
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [page, setPage] = React.useState(0)
    const [fromDate, setFromDate] = React.useState()
    const [toDate, setToDate] = React.useState()
    const [jenisProject, setJenis] = React.useState()
    const [inputState, setInput] = React.useState({
        namaLOP: selectedItem.data.namaLOP,
        STO: selectedItem.data.STO,
        projectId: selectedItem.data.projectId,
        project: selectedItem.data.project,
        statusDeployment: selectedItem.data.statusDeployment,
        biayaJasa: selectedItem.data.biayaJasa,
        biayaMaterial: selectedItem.data.biayaMaterial,
        lokasiLOP: selectedItem.data.lokasiLOP,
        waspang: selectedItem.data.waspang
    })

    // console.log(selectedItem)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSelectProject(100, 1));
    }, []);

    const projectDetail = useSelector(state => state.planning);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleSelect = (e) => {
        let index = 0
        let listArray = []
        let jenis = ''

        const value = e.target.value;
        setInput({
            ...inputState,
            [e.target.name]: value
        });

        if (e.target.name == 'projectId') {
            if (projectDetail.projectItem.data) {
                listArray = projectDetail.projectItem.data.projects
                index = listArray.map(item => item.idProject).indexOf(e.target.value);
                jenis = listArray[index].jenis
            }

            dispatch(getSelectStoByProjectId(e.target.value));
            dispatch(getSelectStatusByJenisProject(jenis));
        }
    }

    const handlefocus = (e) => {
        let index = 0
        let listArray = []
        let jenis = ''

        if (projectDetail.projectItem.data) {
            listArray = projectDetail.projectItem.data.projects
            index = listArray.map(item => item.idProject).indexOf(inputState.projectId);
            jenis = listArray[index].jenis
        }

        dispatch(getSelectStoByProjectId(inputState.projectId));
        dispatch(getSelectStatusByJenisProject(jenis));

    }

    const handleSubmit = (event) => {
        const payload = inputState
        Api.put(`api/planning/lop/${selectedItem.data.idLop}`, payload)
            .then(res => {
                // console.log('response', res.data);
                if (res.data) {
                    toast.success("Data Berhasil di simpan !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/planning/lop')
                } else {
                    toast.success("Gagal memproses data !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    const arrProject = projectDetail.projectItem.data
    const arrSTO = projectDetail.stoByProjectItem.data
    const arrStatus = projectDetail.statusByJenisItem.data

    // console.log(inputState)

    return (
        <Box width="100%">
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '#eee', padding: 20, marginRight: 10 }}>
                <Heading>Ubah LOP</Heading>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} >
                        <div>
                            <Label>Nama LOP</Label>
                            <TextInput
                                sx={{ mb: 2, width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="namaLOP"
                                value={inputState.namaLOP}
                                onChange={handleSelect}
                            />
                        </div>
                        <div>
                            <Label>Project</Label>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={inputState.projectId}
                                onChange={handleSelect}
                                label="Pilih Project"
                                name="projectId"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ mb: 2, width: '100%' }}
                            >
                                <MenuItem value={inputState.projectId} disabled>
                                    {inputState.project}
                                </MenuItem>
                                {
                                    arrProject &&
                                    arrProject.projects.map((item, index) => (
                                        <MenuItem value={item.idProject} key={index} dat={index}>
                                            {item.namaProject}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                        <div>
                            <Label>STO</Label>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={inputState.STO}
                                onChange={handleSelect}
                                onFocus={handlefocus}
                                label="Pilih STO"
                                name="STO"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ mb: 2, width: '100%' }}
                            >
                                <MenuItem value={inputState.STO} disabled>
                                    {inputState.STO}
                                </MenuItem>
                                {
                                    arrSTO &&
                                    arrSTO.stos.map((item, index) => (
                                        <MenuItem value={item.idSTO} key={index}>
                                            {item.namaSTO}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>                        
                        <div>
                            <Label>Status</Label>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={inputState.statusDeployment}
                                onChange={handleSelect}
                                onFocus={handlefocus}
                                label="Pilih Status"
                                name="statusDeployment"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ mb: 2, width: '100%' }}
                            >
                                <MenuItem value={inputState.statusDeployment} disabled>
                                    {inputState.statusDeployment}
                                </MenuItem>
                                {
                                    arrStatus &&
                                    arrStatus.status.map((item, index) => (
                                        <MenuItem value={item.idStatus} key={index}>
                                            {item.namaStatus}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <div>
                            <Label>Nilai Jasa</Label>
                            <NumberFormat
                                thousandSeparator={'.'}
                                decimalSeparator={false}
                                name="biayaJasa"
                                value={inputState.biayaJasa}
                                customInput={TextInput}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    setInput({
                                        ...inputState,
                                        biayaJasa: value
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <Label>Nilai Material</Label>
                            <NumberFormat
                                thousandSeparator={'.'}
                                decimalSeparator={false}
                                name="biayaMaterial"
                                value={inputState.biayaMaterial}
                                customInput={TextInput}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    setInput({
                                        ...inputState,
                                        biayaMaterial: value
                                    });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 20 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={3} md={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                    navigate('/planning/lop')
                                }
                                style={{ width: '100%' }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit()}
                                style={{ width: '100%' }}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    )
}

export default EditLop
