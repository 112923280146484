import { styled, Box } from '@mui/system'
import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import useSettings from 'app/hooks/useSettings'
import { Paragraph, Span } from '../Typography'
import { Icon, ButtonBase } from '@mui/material'
import MatxVerticalNavExpansionPanel from './MatxVerticalNavExpansionPanel'
import { useDispatch, useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { getUserById } from 'app/redux/actions/UsersAction'

const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
    fontSize: '12px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    display: mode === 'compact' && 'none',
    color: theme.palette.text.secondary,
}))

const ExtAndIntCommon = {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px',
    height: 44,
    whiteSpace: 'pre',
    // marginBottom: '8px',
    textDecoration: 'none',
    justifyContent: 'space-between',
    transition: 'all 150ms ease-in',
    '&:hover': {
        background: '#fff',
    },
    '& .bg-icon': {
        padding: 13,
    },
    '&.compactNavItem': {
        overflow: 'hidden',
        justifyContent: 'center !important',
    },
    '& .icon': {
        fontSize: '18px',
        verticalAlign: 'middle',
        color: '#000',
    },
}
const ExternalLink = styled('a')(({ theme }) => ({
    ...ExtAndIntCommon,
    color: theme.palette.text.primary,
}))

const InternalLink = styled(Box)(({ theme }) => ({
    '& a': {
        ...ExtAndIntCommon,
        color: theme.palette.text.primary,
    },
    '& .navItemActive': {
        backgroundColor: '#fff',
        '& .bg-icon': {
            backgroundColor: '#008CA1',
        },
        '& .icon': {
            padding: 0,
            color: '#fff',
        },
        '& .nav-bullet': {
            borderRight: '3px solid #008CA1',
            height: '100%',
        },
    },
}))

const StyledText = styled(Span)(({ mode }) => ({
    fontSize: '0.875rem',
    paddingLeft: '0.8rem',
    display: mode === 'compact' && 'none',
}))

const StyledText2 = styled(Span)(({ mode }) => ({
    fontSize: '0.875rem',
    paddingLeft: '1.2rem',
    display: mode === 'compact' && 'none',
}))

const BulletIcon = styled('div')(({ theme }) => ({
    padding: '2px',
    marginLeft: '50px',
    marginRight: '8px',
    overflow: 'hidden',
    // borderRadius: '300px',
    // background: theme.palette.text.primary,
    // borderRight: '3px solid',
    height: '100%',
}))

const BadgeValue = styled('div')(() => ({
    padding: '1px 8px',
    overflow: 'hidden',
    borderRadius: '300px',
}))

const MatxVerticalNav = ({ items }) => {
    const { settings } = useSettings()
    const { mode } = settings.layout1Settings.leftSidebar
    const locationReact = useLocation()
    const getDataUser = useSelector((state) => state.users)
    const dispatch = useDispatch()
    const localToken = localStorage.getItem('accessToken')
    const [profile, setProfile] = useState(null)
    const [menu, setMenu] = useState([])
    const renderLevels = (data) => {
        return data.map((item, index) => {
            if (item.type === 'label')
                return (
                    <ListLabel
                        key={index}
                        mode={mode}
                        className="sidenavHoverShow"
                    >
                        {item.label}
                    </ListLabel>
                )
            if (item.children) {
                return (
                    <MatxVerticalNavExpansionPanel
                        mode={mode}
                        item={item}
                        key={index}
                    >
                        {renderLevels(item.children)}
                    </MatxVerticalNavExpansionPanel>
                )
            } else if (item.type === 'extLink') {
                return (
                    <ExternalLink
                        key={index}
                        href={item.path}
                        className={`${mode === 'compact' && 'compactNavItem'}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <ButtonBase
                            key={item.name}
                            name="child"
                            sx={{ width: '100%' }}
                        >
                            {(() => {
                                if (item.icon) {
                                    return (
                                        <div className="bg-icon">
                                            <Icon className="icon">
                                                {item.icon}
                                            </Icon>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <span className="item-icon icon-text">
                                            {item.iconText}
                                        </span>
                                    )
                                }
                            })()}
                            <StyledText
                                mode={mode}
                                className="sidenavHoverShow"
                            >
                                {item.name}
                            </StyledText>
                            <Box mx="auto"></Box>
                            {item.badge && (
                                <BadgeValue>{item.badge.value}</BadgeValue>
                            )}
                        </ButtonBase>
                    </ExternalLink>
                )
            } else {
                return (
                    <InternalLink key={index}>
                        <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                                isActive
                                    ? `navItemActive ${
                                          mode === 'compact' && 'compactNavItem'
                                      }`
                                    : `${
                                          mode === 'compact' && 'compactNavItem'
                                      }`
                            }
                        >
                            <ButtonBase
                                id="custome-nav"
                                key={item.name}
                                name="child"
                                sx={{ width: '100%' }}
                                style={{
                                    borderRightColor: '#000',
                                    borderRightWidth: 0.5,
                                }}
                            >
                                {item?.icon ? (
                                    <div className="bg-icon">
                                        {item?.icon === 'menu-peta' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/maps-white.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/maps.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : item?.icon === 'validate-order' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/validate-white.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/validate.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : item?.icon ===
                                          'menu-perencanaan' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/menu/perencanaan-white.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/menu/perencanaan.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : item?.icon ===
                                          'menu-kendala-selesai' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/kendala-selesai-white.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/kendala-selesai.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : item?.icon === 'log-penggunaan' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/menu/log-pengguna-white.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/menu/log-pengguna.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : item?.icon === 'menu-existing' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/validate-existing-white.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/validate-existing.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : item?.icon === 'demand-data' ? (
                                            <>
                                                {locationReact?.pathname ===
                                                item.path ? (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/demand-data-icon.svg'
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                            width: '24px',
                                                            height: '24px',
                                                            marginLeft: '5px',
                                                        }}
                                                        src={
                                                            '/assets/map/demand-data-icon-black.svg'
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <Icon
                                                className="icon"
                                                sx={{ width: 36 }}
                                            >
                                                {item.icon}
                                            </Icon>
                                        )}
                                    </div>
                                ) : (
                                    <Fragment>
                                        <BulletIcon
                                            className={`nav-bullet`}
                                            sx={{
                                                display:
                                                    mode === 'compact' &&
                                                    'none',
                                            }}
                                        />
                                        <Box
                                            className="nav-bullet-text"
                                            sx={{
                                                ml: '20px',
                                                fontSize: '11px',
                                                display:
                                                    mode !== 'compact' &&
                                                    'none',
                                            }}
                                        >
                                            {item.iconText}
                                        </Box>
                                    </Fragment>
                                )}
                                {item?.icon === 'validate-order' ||
                                item?.icon === 'menu-peta' ||
                                item?.icon === 'menu-perencanaan' ||
                                item?.icon === 'menu-existing' ||
                                item?.icon === 'menu-kendala-selesai' ||
                                item?.icon === 'log-penggunaan' ||
                                item?.icon === 'demand-data' ? (
                                    <StyledText2
                                        mode={mode}
                                        className="sidenavHoverShow"
                                    >
                                        {item.name}
                                    </StyledText2>
                                ) : (
                                    <StyledText
                                        mode={mode}
                                        className="sidenavHoverShow"
                                    >
                                        {item.name}
                                    </StyledText>
                                )}
                                <Box mx="auto"></Box>
                                {item.badge && (
                                    <BadgeValue className="sidenavHoverShow">
                                        {item.badge.value}
                                    </BadgeValue>
                                )}
                            </ButtonBase>
                        </NavLink>
                    </InternalLink>
                )
            }
        })
    }
    useEffect(() => {
        const resultToken = localToken ? jwtDecode(localToken) : ''
        dispatch(getUserById(resultToken?.id, 'profile')).then((r) => {
            setProfile(r?.data)
        })
    }, [items])
    useEffect(() => {
        const permissions = profile?.roles[0]?.permissions
        if (profile) {
            const menus = Object.keys(permissions)
            const filterMenu = items?.filter((d) => menus.includes(d?.status))
            setMenu(filterMenu)
        }
    }, [profile])
    return <div className="navigation">{renderLevels(menu)}</div>
}

export default React.memo(MatxVerticalNav)
