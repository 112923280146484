import { red, blue } from '@mui/material/colors'
import { components } from './components'

const themeOptions = {
    typography: {
        fontSize: 14,
        body1: {
            fontSize: '14px',
        },
    },

    status: {
        danger: red[500],
    },
    palette: {
        primary1: {
            light: '#00BAD6',
            main: '#00BAD6',
            dark: '#00BAD6',
            contrastText: '#fff',
        },
        primary2: {
            light: '#008CA1',
            main: '#008CA1',
            dark: '#008CA1',
            contrastText: '#fff',
        },
        primarySuccess: {
            light: '#34d399',
            main: '#34d399',
            dark: '#34d399',
            contrastText: '#fff',
        },
        primary3: {
            light: '#b0b0b0',
            main: '#b0b0b0',
            dark: '#b0b0b0',
            contrastText: '#fff',
        },
        primary4: {
            light: '#0B0A0A',
            main: '#0B0A0A',
            dark: '#0B0A0A',
            contrastText: '#fff',
        },
        primary5: {
            light: '#fff',
            main: '#fff',
            dark: '#fff',
            contrastText: '#b0b0b0',
        },
        primary6: {
            light: '#E60808FF',
            main: '#E60808FF',
            dark: '#E60808FF',
            contrastText: '#fff',
        },
        primary1text: {
            light: '#00BAD6',
            main: '#00BAD6',
            dark: '#00BAD6',
            contrastText: '#01d3f3',
        },
        primaryText2: {
            light: '#008CA1',
            main: '#008CA1',
            dark: '#008CA1',
            contrastText: '#008CA1',
        },
    },

    components: {
        ...components,
    },
}

export default themeOptions
