/* [
    "userManagement",
    "dashboard",
    "demandData",
    "validasiDrder",
    "kendalaOrder",
    "kendalaSelesai",
    "odpExisting",
    "planning",
    "petaSmartplan"
] */
export const navigations = [
    {
        label: 'MENU UTAMA',
        type: 'label',
        status: 'dashboard',
    },
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dvr',
        status: 'dashboard',
    },
    {
        name: 'Validasi Order',
        path: '/validasi-order',
        icon: 'validate-order',
        status: 'validasiDrder',
    },
    {
        name: 'Demand Data',
        path: '/demand-data',
        icon: 'demand-data',
        status: 'demandData',
    },
    {
        name: 'Kendala Order',
        path: '/kendala-order',
        icon: 'kendala-order-icon',
        status: 'kendalaOrder',
        children: [
            {
                name: 'ODP Underspec',
                path: '/kendala-order/odp-underspec',
            },
            {
                name: 'Tidak ada Tiang',
                path: '/kendala-order/tidak-ada-tiang',
            },
            {
                name: 'Jarak Jauh',
                path: '/kendala-order/jarak-jauh',
            },
            {
                name: 'ODP Full',
                path: '/kendala-order/odp-full',
            },
            {
                name: 'Kendala QC PSB',
                path: '/kendala-order/kendala-qc-psb',
            },
        ],
    },
    {
        name: 'Kendala Selesai',
        path: '/kendala-selesai',
        icon: 'menu-kendala-selesai',
        status: 'kendalaSelesai',
    },
    {
        name: 'ODP Existing',
        path: '/odpExistings',
        icon: 'menu-existing',
        status: 'odpExisting',
    },
    {
        name: 'Perencanaan',
        path: '/perencanaan',
        icon: 'menu-perencanaan',
        status: 'planning',
        children: [
            {
                name: 'Daftar Pekerjaan',
                path: '/perencanaan/daftar-pekerjaan',
            },
            {
                name: 'List Of Project',
                path: '/perencanaan/list-of-project',
            },
            {
                name: 'ODP Plan',
                path: '/perencanaan/odp-plan',
            },
            {
                name: 'Daftar Waspang',
                path: '/perencanaan/waspang?name=waspang',
            },
        ],
    },
    {
        name: 'Peta Smartplan',
        path: '/peta-smartplan',
        icon: 'menu-peta',
        status: 'petaSmartplan',
    },

    {
        label: 'User Management',
        type: 'label',
        status: 'userManagement',
    },
    {
        name: 'User Management',
        path: '/users',
        icon: 'person',
        status: 'userManagement',
    },
    {
        name: 'Log Pengguna',
        path: '/Log-penggunaan',
        icon: 'log-penggunaan',
        status: 'userManagement',
    },
]
