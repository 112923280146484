import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL
let accessToken = localStorage.getItem('accessToken')

export const Api = axios.create({
    baseURL,
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': 'Authorization',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${accessToken}`,
    },
    mode: 'no-cors',
    credentials: true,
    crossdomain: true,
})

Api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken')
        config.headers.Authorization = `Bearer ${accessToken}`
        return config
    },
    (error) => Promise.reject(error)
)
