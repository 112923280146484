import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'
import FormOdp from './menu/FormOdp';
import EditOdp from './menu/EditOdp';
import EditLop from './menu/EditLop';

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')));
const FormProject = Loadable(lazy(() => import('./menu/FormProject')));
const FormLop = Loadable(lazy(() => import('./menu/FormLop')));

const planningRoute = [
    {
        path: '/planning/project',
        element: <AppIndex data={{'name': 'project', 'title': 'Daftar Project', 'form': 'formProject'}} />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/lop',
        element: <AppIndex data={{'name': 'lop', 'title': 'Daftar LOP', 'form': 'formLop'}} />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/odpPlan',
        element: <AppIndex data={{'name': 'odpPlan', 'title': 'Daftar ODP Plan', 'form': 'formOdp'}} />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/formProject',
        element: <FormProject />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/formLop',
        element: <FormLop />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/formOdp',
        element: <FormOdp />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/editOdp',
        element: <EditOdp />,
        // auth: authRoles.editor,
    },
    {
        path: '/planning/editLop',
        element: <EditLop />,
        // auth: authRoles.editor,
    },
]

export default planningRoute
