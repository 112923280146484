import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')))

const mapsRoute = [
    {
        path: '/peta-smartplan/:id',
        element: <AppIndex name={'peta-smartplan'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/peta-lop',
        element: <AppIndex name={'peta-lop'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/peta-smartplan',
        element: <AppIndex name={'peta-smartplan'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/peta-smartplan-edit',
        element: <AppIndex name={'peta-smartplan-edit'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/peta-odp-plan',
        element: <AppIndex name={'peta-odp-plan'} />,
        // auth: authRoles.editor,
    },
]

export default mapsRoute
