import { GET_LOG_PENGGUNAAN } from '../actions/LogActions'

const initialState = {
    logPenggunaan: [],
}

const LogReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LOG_PENGGUNAAN: {
            return {
                ...state,
                logPenggunaan: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default LogReducer
