import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')));

const provisioningRoute = [
    {
        path: '/provisioning/shareOrder',
        element: <AppIndex name={'shareOrder'} title={'Bagi Order'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/provisioning/constraints',
        element: <AppIndex name={'constraints'} title={'Kendala Material'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/provisioning/ogp',
        element: <AppIndex name={'ogp'} title={'Provisioning OGP'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/provisioning/completed',
        element: <AppIndex name={'completed'} title={'Completed (PS)'} />,
        // auth: authRoles.editor,
    },
]

export default provisioningRoute
