import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    TablePagination,
    Button,
    Grid,
    TextField,
    List,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { Breadcrumb, SimpleCard } from 'app/components'
import React, { useState, useEffect, useRef } from 'react'
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { useLocation, useNavigate } from 'react-router-dom'
import {
    getOdpExistingByStatus,
    getOdpMarker,
    getSelectLop,
    getSelectStatusOdp,
    getSelectSTObyLOP,
} from '../../../redux/actions/PlanningActions'
import { useSelector, useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Api } from '../../../redux/api'
import { toast } from 'react-toastify'
import {
    MapContainer,
    Popup,
    TileLayer,
    useMapEvents,
    Marker,
    ZoomControl,
} from 'react-leaflet'
import L from 'leaflet'
import MarkerMerah from '../../../assets/marker-merah.png'
import MarkerHijau from '../../../assets/marker-hijau.png'
import MarkerBlack from '../../../assets/marker-black.png'
import MarkerBlue from '../../../assets/marker-blue.png'
import MarkerSystem from '../../../assets/marker-blacksytem.png'
import MarkerYellow from '../../../assets/marker-yellow.png'
import MarkerTosca from '../../../assets/marker-tosca.png'
import MarkerAqua from '../../../assets/marker-aqua.png'
import MarkerUngu from '../../../assets/marker-ungu.png'
import '../../../styles/map.css'
import ChangeMapView from 'app/components/Changmap/changemapView'
import Ports from '../../../assets/connection.png'
import Project from '../../../assets/project.png'
import User from '../../../assets/user.png'
import Setting from '../../../assets/settings.png'
import Status from '../../../assets/spinner-of-dots.png'

// const MyMapComponent = withScriptjs(withGoogleMap((props) =>
//     <GoogleMap
//         defaultZoom={8}
//         defaultCenter={{ lat: -34.397, lng: 150.644 }}
//     >
//         {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
//     </GoogleMap>
// ))
const TextInfo = styled('h6')(({ theme }) => ({
    margin: 0,
    fontSize: '10px',
    color: theme.palette.textLight,
    textAlign: 'left',
    width: '100%',
}))
const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const TextInput = styled(TextField)(() => ({
    width: '100%',
    marginBottom: '16px',
}))

const Heading = styled('h5')(({ theme }) => ({
    margin: 0,
    marginTop: '4px',
    marginBottom: '10px',
    fontWeight: '800',
    fontSize: '18px',
    color: theme.palette.primary.main,
}))

const Label = styled('p')(({ theme }) => ({
    margin: 0,
    marginTop: '4px',
    fontSize: '12px',
    color: theme.palette.textLight,
    textAlign: 'left',
}))

const Valuecolor = ['BLACKSYSTEM', 'BLACK', 'GREEN', 'YELLOW', 'RED']
const iconMerah = (size) => {
    return L.icon({
        iconUrl: MarkerMerah,
        iconSize: [size],
    })
}

const iconHijau = (size) => {
    return L.icon({
        iconUrl: MarkerHijau,
        iconSize: [size],
    })
}
const iconTosca = (size) => {
    return L.icon({
        iconUrl: MarkerTosca,
        iconSize: [size],
    })
}
const iconYellow = (size) => {
    return L.icon({
        iconUrl: MarkerYellow,
        iconSize: [size],
    })
}
const iconAqua = (size) => {
    return L.icon({
        iconUrl: MarkerAqua,
        iconSize: [size],
    })
}
const iconBlack = (size) => {
    return L.icon({
        iconUrl: MarkerBlack,
        iconSize: [size],
    })
}
const iconSystem = (size) => {
    return L.icon({
        iconUrl: MarkerSystem,
        iconSize: [size],
    })
}
const iconBlue = (size) => {
    return L.icon({
        iconUrl: MarkerBlue,
        iconSize: [size],
    })
}
const iconUngu = (size) => {
    return L.icon({
        iconUrl: MarkerUngu,
        iconSize: [size],
    })
}

const IconMarkers = {
    odp: iconUngu,
    deployment: iconAqua,
    RED: iconMerah,
    BLACKSYSTEM: iconBlue,
    BLACK: iconBlack,
    GREEN: iconHijau,
    YELLOW: iconYellow,
}

const FormOdp = (props) => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { action } = state
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [page, setPage] = React.useState(0)
    const [fromDate, setFromDate] = React.useState()
    const [toDate, setToDate] = React.useState()
    const [jenisProject, setJenis] = React.useState()
    const [inputState, setInput] = React.useState({
        ODPName: '',
        STO: '',
        LopId: '',
        kapasitas: '',
        lat: '',
        lng: '',
        statusOCC: '',
    })
    const [error, setError] = React.useState()
    const [checkedOdp, setCheckeds] = React.useState(false)
    const [dataColor, setDataColor] = useState([])
    const checkedOdpStatus = dataColor.length > 0
    const dispatch = useDispatch()
    const [searchData, setSearchData] = useState([])
    const inputRef = useRef(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [boxSearch, setBoxSearch] = useState(false)
    const [datas, setDatas] = useState(
        selectedItem
            ? selectedItem
            : { lat: -3.3085586272066636, lon: 128.9616326667371 }
    )

    useEffect(() => {
        dispatch(getSelectLop(100, 1))
        dispatch(getSelectStatusOdp())
        if (state.action == 'edit') {
            const items = state.data
            // console.log(items)
            setInput({
                ...inputState,
                [inputState.ODPName]: items.ODPName,
                [inputState.LopId]: items.LOP,
            })
        }
    }, [])

    const odpDetail = useSelector((state) => state.planning)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleSelect = (e) => {
        const value = e.target.value
        setInput({
            ...inputState,
            [e.target.name]: value,
        })
    }

    const handleSelectLop = (e) => {
        const value = e.target.value
        setInput({
            ...inputState,
            STO: value.STO,
            LopId: value,
        })
    }

    const handleEditDataItem = () => {
        if (state.action == 'edit') {
            const items = state.data
            // console.log(items)
            setInput([(inputState.ODPName = items.ODPName)])
        }
    }
    const handleChecked = (event) => {
        setCheckeds(event.target.checked)
        if (event.target.checked == true) {
            if (inputState.lat && inputState.lng) {
                // console.log('soni')
                dispatch(getOdpMarker(inputState.lat, inputState.lng))
            }
        }
    }
    const handleValidation = () => {
        let fields = inputState
        let errors = []

        if (!inputState.ODPName) {
            errors.push({
                field: 'Nama Odp',
                message: 'Cannot be empty',
            })
        }
        if (!inputState.LopId) {
            errors.push({
                field: 'LOP',
                message: 'Cannot be empty',
            })
        }
        if (!inputState.lat) {
            errors.push({
                field: 'Lat',
                message: 'Cannot be empty',
            })
        }
        if (!inputState.lng) {
            errors.push({
                field: 'Long',
                message: 'Cannot be empty',
            })
        }
        return {
            errors,
            hasError: errors.length > 0,
        }
    }

    const handleSubmit = (event) => {
        const data = {
            ODPName: inputState.ODPName,
            STO: inputState.STO,
            LopId: inputState.LopId.idLop,
            kapasitas: inputState.kapasitas,
            lat: inputState.lat,
            lng: inputState.lng,
            statusOCC: inputState.statusOCC,
        }
        const validations = handleValidation()
        if (!validations.hasError) {
            Api.post('api/planning/odp', data)
                .then((res) => {
                    // console.log('reject', res.data);
                    if (res.data) {
                        toast.success('Data Berhasil di simpan !', {
                            position: toast.POSITION.TOP_CENTER,
                        })
                        navigate('/planning/odpPlan')
                    } else {
                        toast.success('Gagal memproses data !', {
                            position: toast.POSITION.TOP_CENTER,
                        })
                    }
                })
                .catch((err) => {
                    // console.log(err);
                })
        } else {
            for (const { field, message } of validations.errors) {
                toast.warning(`${field} ${message}`, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        }
        // const payload = inputState
    }
    const arrLOP = odpDetail.lopItem.data
    const arrSTO = odpDetail.stoByLopItem.data
    const arrStatusOdp = odpDetail.statusOdpItem.data

    const handleCheckedsOdpExisting = (theme) => (event) => {
         const maxdistance = 500
        let dataColors = [...dataColor]

        if (theme === 'BLACKSYSTEM') {
            if (event.target.checked) {
                setDataColor((dataColors = [...dataColor, theme]))
            } else {
                const a = [...dataColor]
                if (a.indexOf(theme) != -1) {
                    a.splice(a.indexOf(theme), 1)
                }
                setDataColor((dataColors = a))
            }
        }
        if (theme === 'BLACK') {
            if (event.target.checked) {
                setDataColor((dataColors = [...dataColor, theme]))
            } else {
                const a = [...dataColor]
                if (a.indexOf(theme) != -1) {
                    a.splice(a.indexOf(theme), 1)
                }
                setDataColor((dataColors = a))
            }
        }
        if (theme === 'GREEN') {
            if (event.target.checked) {
                setDataColor((dataColors = [...dataColor, theme]))
            } else {
                const a = [...dataColor]
                if (a.indexOf(theme) != -1) {
                    a.splice(a.indexOf(theme), 1)
                }
                setDataColor((dataColors = a))
            }
        }
        if (theme === 'YELLOW') {
            if (event.target.checked) {
                setDataColor((dataColors = [...dataColor, theme]))
            } else {
                const a = [...dataColor]
                if (a.indexOf(theme) != -1) {
                    a.splice(a.indexOf(theme), 1)
                }
                setDataColor((dataColors = a))
            }
        }
        if (theme === 'RED') {
            if (event.target.checked) {
                setDataColor((dataColors = [...dataColor, theme]))
            } else {
                const a = [...dataColor]
                if (a.indexOf(theme) != -1) {
                    a.splice(a.indexOf(theme), 1)
                }
                setDataColor((dataColors = a))
            }
        }

        dispatch(
            getOdpExistingByStatus({
                // page,
                statusSystem: dataColors,
                maxdistance: maxdistance,
                lat: inputState.lat,
                long: inputState.lng,
            })
        )
    }

    function LocationMarkers() {
        const map = useMapEvents({
            click(e) {
                // markers.push(e.latlng)
                // setMarkers(e.latlng)
                // console.log(e);
                setInput((d) => {
                    return {
                        ...d,
                        ...e.latlng,
                    }
                })
            },
        })

        return (
            <div>
                {inputState.lng && inputState.lat && (
                    <Marker
                        position={[inputState.lat, inputState.lng]}
                        icon={IconMarkers.odp(20)}
                    >
                        <Popup>
                            <span>Lat: {inputState.lat}</span>
                            <br />
                            <span>Lng: {inputState.lng}</span>
                            {/* {console.log(idx)} */}
                        </Popup>
                    </Marker>
                )}
                {checkedOdp &&
                    odpDetail.odpMarker &&
                    odpDetail.odpMarker.data &&
                    odpDetail.odpMarker.data.odps.map((item, index) => (
                        <Marker
                            // key={index}
                            // title={item.ODPName}
                            position={{
                                lat: item.location.lat,
                                lng: item.location.long,
                            }}
                            icon={IconMarkers.deployment(20)}
                        >
                            <Popup>
                                <div>
                                    <p className="p-odp">
                                        <img
                                            src={Setting}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                        {item.ODPName}
                                    </p>
                                    <p className="p-odp">
                                        <img
                                            src={Project}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                        {item.namaProject}
                                    </p>

                                    <p className="p-odp">
                                        <img
                                            src={Ports}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                        {item.kapasitas} Ports
                                    </p>
                                    <p className="p-odp">
                                        <img
                                            src={Status}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                        {item.statusLop}
                                    </p>

                                    <p className="p-odp">
                                        <img
                                            src={User}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />{' '}
                                        {item.demand} Demand
                                    </p>
                                </div>
                            </Popup>
                        </Marker>
                    ))}

                {checkedOdpStatus &&
                    odpDetail.odpExistingStatus &&
                    odpDetail.odpExistingStatus.data &&
                    odpDetail.odpExistingStatus.data.odps.map((item, index) => (
                        <Marker
                            position={{
                                lat: item.location.lat,
                                lng: item.location.long,
                            }}
                            icon={IconMarkers[item.statusSystem](20)}
                        >
                            {' '}
                            <Popup maxWidth={300}>
                                <div>
                                    <p className="p-odp">
                                        <img
                                            src={Setting}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                        {item.ODPName}
                                    </p>
                                    <p className="p-odp">
                                        <img
                                            src={Ports}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                        {item.kapasitas} Ports
                                    </p>
                                    <p className="p-odp">
                                        <img
                                            src={User}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />{' '}
                                        {item.demand} Demand
                                    </p>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
            </div>
        )
    }

    const search = async (e) => {
        setBoxSearch(true)
        // const arryBaru = [];
        return Api.get(
            `api/map/search?search=${
                typeof e === 'string' ? e : e.target.value
            }&offset=0&limit=520`
        ).then((e) => {
            // console.log(e)
            // console.log(e.data.content.results);
            return setSearchData(e.data.data)
        })

        // console.log(e.target.value);
        // setSearchData(e.target.value);
    }

    return (
        <Container>
            <Box width="100%">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={9}>
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Label>ODP Plan</Label>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={
                                                        !!inputState.lat &&
                                                        !!inputState.lng
                                                            ? false
                                                            : true
                                                    }
                                                    checked={checkedOdp}
                                                    onChange={handleChecked}
                                                />
                                            }
                                            label="Deployment Plan"
                                        />
                                    </div>
                                    <div style={{ marginLeft: 40 }}>
                                        <Label>ODP Existing</Label>
                                        {Valuecolor.map((theme, i) => (
                                            <FormControlLabel
                                                key={theme}
                                                control={
                                                    <Checkbox
                                                        onChange={handleCheckedsOdpExisting(
                                                            theme
                                                        )}
                                                        disabled={
                                                            !!inputState.lat &&
                                                            !!inputState.lng
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                }
                                                label={theme}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div
                                    className="input-search"
                                    style={{ marginBottom: 20 }}
                                >
                                    <input
                                        type="text"
                                        ref={inputRef}
                                        placeholder="Search"
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                return (
                                                    setSearchData([]),
                                                    setBoxSearch(false),
                                                    setSelectedItem({
                                                        lat: -5.182212540927572,
                                                        lon: 119.44239623885188,
                                                    })
                                                )
                                            }
                                            search(e)
                                        }}
                                    />
                                </div>
                                <ul
                                    className={
                                        boxSearch
                                            ? 'ul-search active'
                                            : 'ul-search'
                                    }
                                >
                                    {searchData.map((a, index) => {
                                        const b =
                                            'Provinsi ' +
                                            a.provinsi +
                                            ', ' +
                                            'Kota ' +
                                            a.kota +
                                            ',' +
                                            'Kec. ' +
                                            a.kecamatan +
                                            ',' +
                                            'Kel. ' +
                                            a.kelurahan
                                        return (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    inputRef.current.value = b
                                                    search(b).then(() => {
                                                        setSearchData([])
                                                        setBoxSearch(false)
                                                        setSelectedItem(a)
                                                    })
                                                }}
                                            >
                                                {b}
                                            </li>
                                        )
                                    })}
                                </ul>
                                <MapContainer
                                    center={[datas.lat, datas.lon]}
                                    zoom={15}
                                    scrollWheelZoom={false}
                                    className="mapid"
                                    zoomControl={false}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />

                                    <ChangeMapView coords={selectedItem} />
                                    <LocationMarkers />
                                    <ZoomControl position="topright" />
                                </MapContainer>
                            </>
                        </Grid>
                        <Grid
                            xs={12}
                            md={3}
                            style={{ height: 650, marginTop: 80 }}
                        >
                            <Heading>Tambah ODP</Heading>
                            <List
                                style={{ maxHeight: '100%', overflow: 'auto' }}
                            >
                                <div>
                                    <Label>Nama ODP Plan</Label>
                                    <TextInput
                                        sx={{ mb: 2, width: '100%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="ODPName"
                                        value={inputState.ODPName}
                                        onChange={handleSelect}
                                    />
                                </div>
                                <div>
                                    <Label>LOP</Label>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        value={inputState.LopId}
                                        onChange={handleSelectLop}
                                        label="Pilih LOP"
                                        name="LopId"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{ mb: 2, width: '100%' }}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Pilih LOP</em>
                                        </MenuItem>
                                        {arrLOP &&
                                            arrLOP.lops.map((item, index) => (
                                                <MenuItem
                                                    value={item}
                                                    key={index}
                                                >
                                                    {item.namaLOP}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                                <div>
                                    <Label>STO</Label>
                                    <TextInput
                                        sx={{ mb: 2, width: '100%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                        name="STO"
                                        value={inputState.STO}
                                        // onChange={handleSelect}
                                    />
                                </div>
                                <div>
                                    <Label>Kapasitas</Label>
                                    <TextInput
                                        sx={{ mb: 2, width: '100%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={inputState.kapasitas}
                                        onChange={handleSelect}
                                        // label="Pilih LOP"
                                        name="kapasitas"
                                    />
                                </div>
                                <div>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        style={{ marginBottom: 20 }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={6}>
                                                <Label>
                                                    Lattitude
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        *
                                                    </span>
                                                </Label>
                                                <TextInput
                                                    required
                                                    id="filled-required"
                                                    sx={{
                                                        mb: 2,
                                                        width: '100%',
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="lat"
                                                    value={inputState.lat}
                                                    onChange={handleSelect}
                                                    disabled
                                                />
                                                <span style={{ color: 'red' }}>
                                                    {error && error['lat']}
                                                </span>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Label>
                                                    Longitude
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        *
                                                    </span>
                                                </Label>
                                                <TextInput
                                                    required
                                                    id="filled-required"
                                                    sx={{
                                                        mb: 2,
                                                        width: '100%',
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="lng"
                                                    value={inputState.lng}
                                                    onChange={handleSelect}
                                                    disabled
                                                />
                                                <span style={{ color: 'red' }}>
                                                    {error && error['long']}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <Label>Status Deployment</Label>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        value={inputState.statusOCC}
                                        onChange={handleSelect}
                                        label="Pilih Status"
                                        name="statusOCC"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{ mb: 2, width: '100%' }}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Pilih Status</em>
                                        </MenuItem>
                                        <MenuItem value="Go Live">
                                            <em>Go Live</em>
                                        </MenuItem>
                                        <MenuItem value="BOQ Rejected">
                                            <em>BOQ Rejected</em>
                                        </MenuItem>
                                        <MenuItem value="DRM Approved">
                                            <em>DRM Approved</em>
                                        </MenuItem>
                                        <MenuItem value="DRM Rejected">
                                            <em>DRM Rejected</em>
                                        </MenuItem>
                                        <MenuItem value="Surver on Desk">
                                            <em>Surver on Desk</em>
                                        </MenuItem>
                                        <MenuItem value="Preparing">
                                            <em>Preparing</em>
                                        </MenuItem>
                                        <MenuItem value="Delivery Material">
                                            <em>Delivery Material</em>
                                        </MenuItem>
                                        <MenuItem value="Material on Site">
                                            <em>Material on Site</em>
                                        </MenuItem>
                                        <MenuItem value="Material on Site">
                                            <em>Material on Site</em>
                                        </MenuItem>
                                        <MenuItem value="instalasi">
                                            <em>instalasi</em>
                                        </MenuItem>
                                        <MenuItem value="instalasi Done">
                                            <em>instalasi Done</em>
                                        </MenuItem>
                                        <MenuItem value="Comtest">
                                            <em>Comtest</em>
                                        </MenuItem>
                                        <MenuItem value="Uji Terima">
                                            <em>Uji Terima</em>
                                        </MenuItem>
                                        <MenuItem value="Comtest">
                                            <em>Validasi ABD</em>
                                        </MenuItem>
                                        <MenuItem value="Inventory">
                                            <em>Inventory</em>
                                        </MenuItem>
                                        <MenuItem value="Drawing">
                                            <em>Drawing</em>
                                        </MenuItem>
                                        <MenuItem value="Terminasi UIM">
                                            <em>Terminasi UIM</em>
                                        </MenuItem>
                                    </Select>
                                </div>

                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    style={{ marginBottom: 20 }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={3} md={3}>
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                onClick={() => navigate(-1)}
                                                style={{ width: '100%' }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => handleSubmit()}
                                                style={{ width: '100%' }}
                                            >
                                                Simpan
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default FormOdp
