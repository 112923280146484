import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')))

const kendalaOrderRoute = [
    {
        path: '/kendala-order/odp-underspec',
        element: <AppIndex name={'odp-underspec'} title={'ODP Underspec'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/kendala-order/tidak-ada-tiang',
        element: (
            <AppIndex name={'tidak-ada-tiang'} title={'Tidak ada Tiang'} />
        ),
        // auth: authRoles.editor,
    },
    {
        path: '/kendala-order/jarak-jauh',
        element: <AppIndex name={'jarak-jauh'} title={'Jarak Jauh'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/kendala-order/odp-full',
        element: <AppIndex name={'odp-full'} title={'ODP Full'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/kendala-order/kendala-qc-psb',
        element: <AppIndex name={'kendala-qc-psb'} title={'Kendala QC PSB'} />,
        // auth: authRoles.editor,
    },
]

export default kendalaOrderRoute
