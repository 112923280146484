import { GET_SALES } from '../actions/DemandDataAction'

const initialState = {
    salesList: [],
}

const DemandDataReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SALES: {
            return {
                ...state,
                salesList: action.payload,
            }
        }

        default: {
            return {
                ...state,
            }
        }
    }
}

export default DemandDataReducer
