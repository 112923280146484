import { useMap } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

export default function ChangeMapView({ coords }) {
    const map = useMap()

    if (coords !== null && coords?.lat && coords?.lon)
        map.setView([coords?.lat, coords?.lon])

    return null
}
