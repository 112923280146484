import objectToQuery from 'app/utils/queryParser'
import axios from 'axios'
import { Api } from '../api'

export const GET_PRESALES_LIST = 'GET_PRESALES_LIST'
export const GET_PRESALES_BY_ID = 'GET_PRESALES_BY_ID'
export const GET_ODP_MARKER = 'GET_ODP_MARKER'
export const GET_SELECT_ODP = 'GET_SELECT_ODP'
export const GET_SELECT_REGIONAL = 'GET_SELECT_REGIONAL'
export const GET_SELECT_WITEL = 'GET_SELECT_WITEL'
export const GET_SELECT_DATEL = 'GET_SELECT_DATEL'
export const GET_SELECT_TEKNISI = 'GET_SELECT_TEKNISI'
export const GET_SELECT_MITRA = 'GET_SELECT_MITRA'
export const GET_SELECT_STO_BY_DATEL = 'GET_SELECT_STO_BY_DATEL'
export const GET_SELECT_ODP_BY_STO = 'GET_SELECT_ODP_BY_STO'
export const GET_SALES_LIST = 'GET_SALES_LIST'
export const GET_SALES_BY_ID = 'GET_SALES_BY_ID'
export const GET_SALES_LIST_STATUS = 'GET_SALES_LIST_STATUS'
export const GET_ODP_EXISTING_BY_STATUS = 'GET_ODP_EXISTING_BY_STATUS'

export const getPreSalesList = (limit = 20, page = 1, query = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `/api/presales?${query}&page=${
                    page + 1
                }&pageLimit=${limit}&apakahOrderPresales=Presales`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_PRESALES_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSalesList = (limit = 20, page = 1, query = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `/api/sales?${query}&page=${page + 1}&pageLimit=${limit}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SALES_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getOdpMarker = (lat, long) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `/api/map/odp/terdekat?lat=${lat}&long=${long}&maxdistance=500`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_ODP_MARKER,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectOdp = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/odp?page=1&pageLimit=100&miniResponse=true`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_ODP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getSelectSto = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/odp?page=1&pageLimit=100&miniResponse=true`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_ODP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectRegional = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/regional`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_REGIONAL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectWitel = (regional) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/witel?regional=${regional}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_WITEL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectDatel = (witel = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/datel?witel=${witel}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_DATEL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectTeknisi = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('api/sales/teknisi')
            let data = resp.data ? resp.data.data.teknisi : []

            dispatch({
                type: GET_SELECT_TEKNISI,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectMitra = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('api/sales/mitra')
            let data = resp.data ? resp.data.data.mitra : []

            dispatch({
                type: GET_SELECT_MITRA,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectSTObyDatel = (datel) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/sto?datel=${datel}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_STO_BY_DATEL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectODPbySTO = (lat, long, STO) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/map/odp/terdekat?lat=${lat}&long=${long}&maxdistance=50000&STO=${STO}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_ODP_BY_STO,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getPreSalesById = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/presales/${id}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_PRESALES_BY_ID,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSalesById = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/sales/${id}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SALES_BY_ID,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSalesListStatus = (limit = 20, page = 1, status, query = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `/api/sales?${query}&page=${
                    page + 1
                }&pageLimit=${limit}&statusOrder=${status}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SALES_LIST_STATUS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}


export const getOdpExistingByStatus = (query = {}) => {
    query = objectToQuery(
        Object.assign(
            // {
            //     statusOCC: 'Go Live',
            // },
            query
        )
    )
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/map/odp/existing/terdekat${query}`)
            // console.log(resp);
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_ODP_EXISTING_BY_STATUS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
