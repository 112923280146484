import {
    GET_USERS_LIST,
    GET_USERS_BY_ID,
    GET_USERS_APPROVAL,
    ADD_USER_APPROVAL,
    DELETE_USER_APPROVAL,
    UPDATE_PROFILE,
} from '../actions/UsersAction'

const initialState = {
    usersList: [],
    usersListApproval: [],
    usersSuccess: [],
    count: 0,
    detailUser: null,
    updateProfile: null,
}

const UsersReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS_LIST: {
            return {
                ...state,
                usersList: action.data,
                count: action.count,
            }
        }
        case GET_USERS_BY_ID: {
            return {
                ...state,
                detailUser: action.payload,
            }
        }
        case GET_USERS_APPROVAL: {
            return {
                ...state,
                usersListApproval: action.data,
            }
        }
        case UPDATE_PROFILE: {
            return {
                ...state,
                updateProfile: action.payload,
            }
        }
        case DELETE_USER_APPROVAL: {
            return {
                ...state,
                usersListApproval: action.payload,
            }
        }
        case ADD_USER_APPROVAL: {
            return {
                ...state,
                data: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default UsersReducer
