import {
    GET_STO,
    GET_SEGMENT,
    GET_DATA_VALIDATE_ORDER,
    CHECK_STATUS,
    GET_DETAIL_VALIDATE_ORDER,
    GET_STATUS_ORDER,
    DELETE_VALIDATE_ORDER,
    GET_HERO,
} from '../actions/ValidateOrderAction'
import { GET_DETAIL_ODP } from '../actions/PerencanaanActions'
const initialState = {
    STOList: [],
    heroList: [],
    segmentList: [],
    validateOrderList: [],
    validateOrderDetail: null,
    validateOrderDeleteDetail: null,
    statusOrderList: [],
    dataDetailOdp: [],
    checkStatus: {
        bool: false,
        status: '',
        data: null,
    },
}

const PerencanaanReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_STO: {
            return {
                ...state,
                STOList: action.payload,
            }
        }
        case GET_SEGMENT: {
            return {
                ...state,
                segmentList: action.payload,
            }
        }
        case GET_DETAIL_ODP: {
            return {
                ...state,
                dataDetailOdp: action.payload,
            }
        }
        case GET_DATA_VALIDATE_ORDER: {
            return {
                ...state,
                validateOrderList: action.payload,
            }
        }
        case GET_DETAIL_VALIDATE_ORDER: {
            return {
                ...state,
                validateOrderDetail: action.payload,
            }
        }
        case CHECK_STATUS: {
            return {
                ...state,
                checkStatus: action.payload,
            }
        }
        case GET_STATUS_ORDER: {
            return {
                ...state,
                statusOrderList: action.payload,
            }
        }
        case DELETE_VALIDATE_ORDER: {
            return {
                ...state,
                validateOrderDeleteDetail: action.payload,
            }
        }
        case GET_HERO: {
            return {
                ...state,
                heroList: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default PerencanaanReducer
