import { Api } from '../api'

export const GET_STO = 'GET_STO'
export const GET_SEGMENT = 'GET_SEGMENT'
export const GET_DATA_VALIDATE_ORDER = 'GET_DATA_VALIDATE_ORDER'
export const GET_DETAIL_VALIDATE_ORDER = 'GET_DETAIL_VALIDATE_ORDER'
export const CHECK_STATUS = 'CHECK_STATUS'
export const GET_STATUS_ORDER = 'GET_STATUS_ORDER'
export const GET_HERO = 'GET_HERO'
export const DELETE_VALIDATE_ORDER = 'DELETE_VALIDATE_ORDER'
export const GET_USIA = 'GET_USIA'
export const GET_STATUS_ODP = 'GET_STATUS_ODP'
export const getSTO = (regional = null, witel = null) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/sto`, {
                params: {
                    regional,
                    witel,
                },
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_STO,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getHero = (regional = null, witel = null) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/hero`, {
                params: {
                    regional,
                    witel,
                },
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_HERO,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getStatus = (regional = null, witel = null) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/existing/dropdown/status-odp`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_STATUS_ODP,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getUsiaOdp = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/existing/dropdown/usia-odp`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_USIA,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getValidasiSummery = (
    form = {
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/validasi/summary`, {
                params: form,
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_HERO,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSegment = (regional = null, witel = null) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/segment`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_SEGMENT,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getAgent = (regional = null, witel = null) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/segment`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_SEGMENT,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const addValidateOrder = (
    form = {
        namaPelanggan: '',
        noKontakPelanggan: '',
        alamat: '',
        regional: '',
        witel: '',
        STO: '',
        lat: 0,
        long: 0,
        trackID: '',
        SCNumber: '',
        segment: '',
        agent: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`/api/presales`, form)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_SEGMENT,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const addDemand = (
    form = {
        namaPelanggan: '',
        noKontakPelanggan: '',
        alamat: '',
        regional: '',
        witel: '',
        STO: '',
        lat: 0,
        long: 0,
        trackID: '',
        SCNumber: '',
        segment: '',
        agent: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`/api/presales`, form)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_SEGMENT,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getStatusOrder = (orderId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/order/status`, {
                params: {
                    orderId: orderId,
                },
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_STATUS_ORDER,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateValidateOrder = (
    form = {
        namaPelanggan: '',
        noKontakPelanggan: '',
        alamat: '',
        regional: '',
        witel: '',
        STO: '',
        lat: 0,
        lng: 0,
        statusOrder: '',
        trackID: '',
        SCNumber: '',
        segment: '',
        agent: '',
    },
    id = ''
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`/api/presales/${id}`, form)
            let data = resp.data ? resp.data : null
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const updateDemandData = (
    form = {
        namaPelanggan: '',
        noKontakPelanggan: '',
        alamat: '',
        regional: '',
        witel: '',
        STO: '',
        lat: 0,
        lng: 0,
        ODPId: null,
        statusOrder: '',
        trackID: '',
        SCNumber: '',
        segment: '',
        agent: '',
    },
    id = ''
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`/api/sales/${id}`, form)
            let data = resp.data ? resp.data : null
            /*dispatch({
                type: GET_SEGMENT,
                payload: data,
            })*/
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const importValidasiOrder = (form) => {
    return async (dispatch) => {
        try {
            const formData = new FormData()
            formData.append('', form)
            const resp = await Api.post(`/api/sales/`, formData)
            let data = resp.data ? resp.data : null
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const deleteValidateOrder = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`/api/presales/${id}`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: DELETE_VALIDATE_ORDER,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getValidateOrder = (
    form = { page: 1, pageLimit: 10, statusOrder: 'Presales' }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/presales`, { params: form })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_DATA_VALIDATE_ORDER,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getIdValidateOrder = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/presales/${id}`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_DETAIL_VALIDATE_ORDER,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const boolValidateOrder = (
    form = {
        bool: false,
        status: '',
        data: null,
    }
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CHECK_STATUS,
                payload: form,
            })
        } catch (err) {
            throw err?.response?.data
        }
    }
}
