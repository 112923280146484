import objectToQuery from 'app/utils/queryParser'
import axios from 'axios'
import { Api } from '../api'

export const GET_PROJECT_LIST = 'GET_PROJECT_LIST'
export const GET_LOP_LIST = 'GET_LOP_LIST'
export const GET_ODP_LIST = 'GET_ODP_LIST'
export const GET_SELECT_SEGMEN = 'GET_SELECT_SEGMEN'
export const GET_SELECT_JENIS = 'GET_SELECT_JENIS'
export const GET_SELECT_REGIONAL = 'GET_SELECT_REGIONAL'
export const GET_SELECT_WITEL = 'GET_SELECT_WITEL'
export const GET_SELECT_STO_BY_PROJECT = 'GET_SELECT_STO_BY_PROJECT'
export const GET_SELECT_PROJECT = 'GET_SELECT_PROJECT'
export const GET_SELECT_STATUS_BY_JENIS_PROJECT =
    'GET_SELECT_STATUS_BY_JENIS_PROJECT'
export const GET_SELECT_LOP = 'GET_SELECT_LOP'
export const GET_SELECT_STO_BY_LOP = 'GET_SELECT_STO_BY_LOP'
export const GET_SELECT_DATEL = 'GET_SELECT_DATEL'
export const GET_ODP_EXISTING = 'GET_ODP_EXISTING'
export const GET_ODP_EXISTING2 = 'GET_ODP_EXISTING2'
export const GET_SELECT_STATUS_ODP = 'GET_SELECT_STATUS_ODP'
export const GET_SUMMARY_ODP = 'GET_SUMMARY_ODP'
export const GET_ODP_EXISTING_BY_ID = 'GET_ODP_EXISTING_BY_ID'
export const GET_ODP_MARKER = 'GET_ODP_MARKER'
export const GET_ODP_EXISTING_BY_STATUS = 'GET_ODP_EXISTING_BY_STATUS'
export const GET_SUMMARY_ODP_PLAN = 'GET_SUMMARY_ODP_PLAN'
export const GET_SUMMARY_PROJECT = 'GET_SUMMARY_PROJECT'
export const GET_SUMMARY_LOP = 'GET_SUMMARY_LOP'

export const getProjectList = (limit = 20, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/project?page=${page + 1}&pageLimit=${limit}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_PROJECT_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getlopList = (limit = 20, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/lop?page=${page + 1}&pageLimit=${limit}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_LOP_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getOdpList = (limit = 20, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/odp?page=${page + 1}&pageLimit=${limit}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_ODP_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectSegmen = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/project/segmen`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_SEGMEN,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectJenis = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/project/jenis`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_JENIS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectProject = (limit = 100, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/project?page=${page}&pageLimit=${limit}&miniResponse=true`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_PROJECT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectLop = (limit = 100, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/lop?page=${page}&pageLimit=${limit}&miniResponse=true`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_LOP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectRegional = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/regional`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_REGIONAL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectWitel = (regional) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/witel?regional=${regional}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_WITEL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getSelectDatel = (witel) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/datel?witel=${witel}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_DATEL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectStoByProjectId = (project) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/sto?idProject=${project}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_STO_BY_PROJECT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectStatusByJenisProject = (jenis) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/project/status?jenis=${jenis}`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_STATUS_BY_JENIS_PROJECT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectSTObyLOP = (lop) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/sto?idLop=${lop}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_STO_BY_LOP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectStatusOdp = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/odp/status`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SELECT_STATUS_ODP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getOdpExisting = (limit = 20, page = 1, query = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/planning/odp?${query}&page=${
                    page + 1
                }&pageLimit=${limit}&statusOCC=Go Live`
            )
            // console.log(resp);
            let data = resp.data ? resp.data : []
            let count = resp.data ? resp.data.totalData : 0

            dispatch({
                type: GET_ODP_EXISTING,
                data,
                count,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getOdpExistingByid = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/existing/odp/${id}`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_ODP_EXISTING_BY_ID,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const importOdpExistingSummery = (form) => {
    return async (dispatch) => {
        try {
            const formData = new FormData()
            formData.append('', form)
            const resp = await Api.post(`/api/existing/odp/`, formData)
            let data = resp.data ? resp.data : null
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getOdpExisting2 = (
    form = {
        pageLimit: 10,
        page: 1,
        regional: '',
        witel: '',
        isDeployment: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/odp`, {
                params: form,
            })
            let data = resp.data ? resp.data : []
            let count = resp.data ? resp.data.totalData : 0

            dispatch({
                type: GET_ODP_EXISTING2,
                payload: {
                    data,
                    count,
                },
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSummaryODP = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/summary/odp/existing`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SUMMARY_ODP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getOdpMarker = (lat, long) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `/api/map/odp/terdekat?lat=${lat}&long=${long}&maxdistance=500`
            )
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_ODP_MARKER,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getOdpExistingByStatus = (query = {}) => {
    query = objectToQuery(
        Object.assign(
            // {
            //     page: 1,
            //     statusOCC: 'Go Live',
            // },
            query
        )
    )
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/map/odp/existing/terdekat${query}`)
            // console.log(resp);
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_ODP_EXISTING_BY_STATUS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSummaryODPPlan = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/summary/odp/planning`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SUMMARY_ODP_PLAN,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getODPexisitingSummery = (
    status = {
        isDeployment: false,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/summary/odp/`, {
                params: status,
            })
            let data = resp.data ? resp.data : []

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getDashboardSummery = (
    form = {
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            let isFormEmpty = true
            for (let key in form) {
                if (form[key] !== '') {
                    isFormEmpty = false
                    break
                }
            }

            const result = isFormEmpty ? null : form

            const resp = await Api.get(`api/dashboard/demand`, {
                params: result,
            })
            let data = resp.data ? resp.data : []

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getDashboardSummeryProject = (
    form = {
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            let isFormEmpty = true
            for (let key in form) {
                if (form[key] !== '') {
                    isFormEmpty = false
                    break
                }
            }

            const result = isFormEmpty ? null : form

            const resp = await Api.get(`api/dashboard/project`, {
                params: result,
            })
            let data = resp.data ? resp.data : []

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getLopSummery = (
    status = {
        isDeployment: false,
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/lop/summary`, {
                params: status,
            })
            let data = resp.data ? resp.data : []

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getOdpSummery = (
    status = {
        isDeployment: false,
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/odp/summary`, {
                params: status,
            })
            let data = resp.data ? resp.data : []

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSummaryProject = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/summary/project`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SUMMARY_PROJECT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSummaryLOP = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/summary/lop`)
            let data = resp.data ? resp.data : []

            dispatch({
                type: GET_SUMMARY_LOP,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
