import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')))
const EditDataOdpUnspec = Loadable(
    lazy(() => import('./menu/EditDataOdpUnspec'))
)
const EditDataOdpFull = Loadable(lazy(() => import('./menu/EditDataOdpFull')))
const EditDataNoPole = Loadable(
    lazy(() => import('./menu/EditDataNoPole'))
)
const EditDataJarakJauh = Loadable(
    lazy(() => import('./menu/EditDataJarakJauh'))
)

const alproRoute = [
    {
        path: '/alpro/odpUnspec',
        element: <AppIndex name={'odpUnspec'} title={'ODP Unspec'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/noPole',
        element: <AppIndex name={'noPole'} title={'Tidak Ada Tiang'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/longDistance',
        element: <AppIndex name={'longDistance'} title={'Jarak Jauh'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/odpFull',
        element: <AppIndex name={'odpFull'} title={'ODP Full'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/odpUnspec/editData',
        element: <EditDataOdpUnspec />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/noPole/editData',
        element: <EditDataNoPole />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/longDistance/editData',
        element: <EditDataJarakJauh />,
        // auth: authRoles.editor,
    },
    {
        path: '/alpro/odpFull/editData',
        element: <EditDataOdpFull />,
        // auth: authRoles.editor,
    },
]

export default alproRoute
