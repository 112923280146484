import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const KendalaSelesai = Loadable(lazy(() => import('./menu/KendalaSelesai')))

const kendalaSelesaiRoute = [
    {
        path: '/kendala-selesai',
        element: <KendalaSelesai />,
        // auth: authRoles.editor,
    },
]

export default kendalaSelesaiRoute
