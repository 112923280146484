import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')))

const perencanaanRoute = [
    {
        path: '/perencanaan/daftar-pekerjaan',
        element: (
            <AppIndex name={'daftar-pekerjaan'} title={'daftar Pekerjaan'} />
        ),
        // auth: authRoles.editor,
    },
    {
        path: '/perencanaan/list-of-project',
        element: (
            <AppIndex name={'list-of-project'} title={'List Of Project'} />
        ),
        // auth: authRoles.editor,
    },
    {
        path: '/perencanaan/odp-plan',
        element: <AppIndex name={'odp-plan'} title={'ODP Plan'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/perencanaan/waspang',
        element: <AppIndex name={'waspang'} title={'Waspang'} />,
        // auth: authRoles.editor,
    },
]

export default perencanaanRoute
