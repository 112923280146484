import { Api } from '../api'

export const GET_STO = 'GET_STO'
export const GET_DETAIL_ODP = 'GET_DETAIL_ODP'
export const createPerencanaan = (
    form = {
        regional: '',
        witel: '',
        namaProject: '',
        segment: '',
        jenis: '',
        tahunProject: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`/api/planning/project`, form)
            let data = resp.data ? resp.data : null
            /*  dispatch({
                type: GET_STO,
                payload: data,
            }) */
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const updatePerencanaan = (
    id = '',
    form = {
        regional: '',
        witel: '',
        namaProject: '',
        segment: '',
        jenis: '',
        tahunProject: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`/api/planning/project/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const listPerencanaan = (
    form = {
        page: 1,
        pageLimit: 10,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/project`, {
                params: form,
            })
            let data = resp.data ? resp.data : null
            /*  dispatch({
                type: GET_STO,
                payload: data,
            }) */
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const listPekerjaan = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/project/category`)
            let data = resp.data ? resp.data : null
            /*  dispatch({
                type: GET_STO,
                payload: data,
            }) */
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listSegment = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/project/segment`)
            let data = resp.data ? resp.data : null
            /*  dispatch({
                type: GET_STO,
                payload: data,
            }) */
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listLop = (
    form = {
        page: 1,
        pageLimit: 10,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/lop`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listPekerjaanLop = (
    form = {
        regional: '',
        witel: '',
        check: false,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/project/dropdown`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listWaspangLop = (
    form = {
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/waspang/dropdown`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const listMitra = (
    form = {
        page: 1,
        limit: 10,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/mitra`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const createMitra = (
    form = {
        regional: '',
        witel: '',
        mitra: '',
        kontak: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`api/planning/mitra`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateMitra = (
    id,
    form = {
        regional: '',
        witel: '',
        mitra: '',
        kontak: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/planning/mitra/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const deleteMitra = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`api/planning/mitra/${id}`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

//  TODO: DROPDOWN MITRA

export const listMitraDropDown = (
    form = {
        regional: '',
        witel: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/mitra/dropdown`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
//  TODO: SUB MITRA DROPDOWN

export const listSubMitraDropDown = (
    form = {
        regional: '',
        witel: '',
        mitraId: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/submitra/dropdown`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
//  TODO: SUB MITRA

export const listSubMitra = (
    form = {
        page: 1,
        limit: 10,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/submitra`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const createSubMitra = (
    form = {
        regional: '',
        witel: '',
        submitra: '',
        kontak: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`api/planning/submitra`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateSubMitra = (
    id,
    form = {
        regional: '',
        witel: '',
        submitra: '',
        kontak: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/planning/submitra/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const deleteSubMitra = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`api/planning/submitra/${id}`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
// TODO: LIST LOP
export const listStatusLop = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/lop/statusDeployment`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const detailStatusLop = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/lop/detail/${id}`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_DETAIL_ODP,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const createLop = (
    form = {
        regional: '',
        witel: '',
        STO: '',
        namaLOP: '',
        projectId: '',
        mitra: '',
        subMitra: '',
        lokasiLOP: '',
        waspang: '',
        statusDeployment: '',
        toc: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`api/planning/lop`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateLop = (
    id = '',
    form = {
        regional: '',
        witel: '',
        namaProject: '',
        segment: '',
        jenis: '',
        tahunProject: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/planning/lop/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const deleteLop = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`api/planning/lop/${id}`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const createWaspang = (
    form = {
        regional: '',
        witel: '',
        waspang: '',
        kontak: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`api/planning/waspang`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateWaspang = (
    id,
    form = {
        regional: '',
        witel: '',
        waspang: '',
        kontak: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/planning/waspang/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listWaspang = (
    form = {
        page: 1,
        pageLimit: 10,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/planning/waspang`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const deleteWaspang = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`api/planning/waspang/${id}`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const infoWaspangLop = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/lop/info/${id}`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const infoOdpMap = (
    form = {
        isDeployment: true,
    },
    id = ''
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/odp/info/${id}`, {
                params: form,
            })

            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listLopDropdown = (
    form = { regional: '', witel: '', STO: '', check: false }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/lop/dropdown`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const recentOdpMap = (form = { regional: '', witel: '' }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/odp/recent`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const listOdpPlan = (
    form = {
        page: 1,
        pageLimit: 10,
        miniResponse: false,
        regional: '',
        witel: '',
        planning: false,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/planning/odp`, {
                params: form,
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const createOdpPlan = (
    form = {
        regional: '',
        witel: '',
        STO: '',
        ODPName: '',
        lopId: '',
        lat: 0,
        long: 0,
        kapasitas: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`api/planning/odp`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateOdpPlan = (
    id = '',
    form = {
        regional: '',
        witel: '',
        STO: '',
        ODPName: '',
        lopId: '',
        lat: 0,
        long: 0,
        kapasitas: 0,
    },
    isDeployment = true
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/planning/odp/${id}`, form, {
                params: {
                    isDeployment: isDeployment,
                },
            })
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const updateOdpPlanMap = (
    id = '',
    form = {
        STO: '',
        ODPName: '',
        lopId: '',
        lat: 0,
        long: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/planning/odp/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const deleteOdpPlanMap = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`api/planning/odp/${id}`)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
