import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'
import preSalesRoute from 'app/views/presales/PreSalesRoute'
import salesOperationRoute from 'app/views/salesoperation/SalesOperationRoute'
import alproRoute from 'app/views/alpro/AlproRoute'
import provisioningRoute from 'app/views/provisioning/ProvisioningRoute'
import planningRoute from 'app/views/planning/PlanningRoute'
import OdpExsitingRoute from 'app/views/odpExisting/OdpExsitingRoute'
import MapsRoute from 'app/views/peta-smartplan/MapsRoute'
import ValidateOrderRoute from 'app/views/validate-order/ValidateOrderRoute'
import UsersRoute from 'app/views/users/UsersRoute'
import KendalaOrderRoute from 'app/views/kendala-order/KendalaOrderRoute'
import DemandDataRoute from 'app/views/demand-data/DemandDataRoute'
import KendalaSelesaiRoute from 'app/views/kendala-selesai/KendalaSelesaiRoute'
import LogPenggunaan from 'app/views/log-pengunaan/Log-penggunaan'
import PerecanaanRoute from 'app/views/perencanaan/PerencanaanRoute'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...dashboardRoutes,
                ...alproRoute,
                ...materialRoutes,
                ...preSalesRoute,
                ...salesOperationRoute,
                ...provisioningRoute,
                ...planningRoute,
                ...OdpExsitingRoute,
                ...UsersRoute,
                ...MapsRoute,
                ...ValidateOrderRoute,
                ...KendalaOrderRoute,
                ...DemandDataRoute,
                ...KendalaSelesaiRoute,
                ...PerecanaanRoute,
                ...LogPenggunaan,
            ],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="dashboard/default" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
