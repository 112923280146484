import { Api } from '../api'

export const GET_LEVEL = 'GET_LEVEL'
export const GET_ROLE = 'GET_ROLE'
export const GET_SUCCESS = 'GET_SUCCESS'
export const getLevel = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/level`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_LEVEL,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getRole = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/role`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_ROLE,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const acceptApproval = (userId, roleId, levelId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/approval/accept`, {
                params: {
                    userId,
                    roleId,
                    levelId,
                },
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_SUCCESS,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
