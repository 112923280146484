import {
    GET_PROJECT_LIST,
    GET_LOP_LIST,
    GET_ODP_LIST,
    GET_SELECT_SEGMEN,
    GET_SELECT_JENIS,
    GET_SELECT_PROJECT,
    GET_SELECT_REGIONAL,
    GET_SELECT_WITEL,
    GET_SELECT_STO_BY_PROJECT,
    GET_SELECT_STATUS_BY_JENIS_PROJECT,
    GET_SELECT_LOP,
    GET_SELECT_STO_BY_LOP,
    GET_SELECT_STATUS_ODP,
    GET_SELECT_DATEL,
    GET_ODP_EXISTING,
    GET_SUMMARY_ODP,
    GET_ODP_EXISTING_BY_ID,
    GET_ODP_MARKER,
    GET_ODP_EXISTING_BY_STATUS,
    GET_SUMMARY_ODP_PLAN,
    GET_SUMMARY_PROJECT,
    GET_SUMMARY_LOP,
    GET_ODP_EXISTING2,
} from '../actions/PlanningActions'

const initialState = {
    projectList: [],
    lopList: [],
    odpList: [],
    segmenItem: [],
    jenisItem: [],
    projectItem: [],
    regionalItem: [],
    witelItemItem: [],
    stoByProjectItem: [],
    datelItem: [],
    statusByJenisItem: [],
    lopItem: [],
    stoByLopItem: [],
    odpExisting: [],
    odpExistingId: [],
    statusOdpItem: [],
    summaryODP: [],
    odpMarker: [],
    odpExistingStatus: [],
    opdSummaryPlan: [],
    summaryProject: [],
    summaryLop: [],
    count: 0,
    loading: true,
    dataOdpExisting: null,
}

const PlanningReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PROJECT_LIST: {
            return {
                ...state,
                dataOdpExisting: action.payload,
            }
        }
        case GET_ODP_EXISTING2: {
            return {
                ...state,
                projectList: action.data,
                loading: false,
            }
        }
        case GET_ODP_MARKER: {
            return {
                ...state,
                odpMarker: action.data,
                loading: false,
            }
        }
        case GET_ODP_EXISTING_BY_STATUS: {
            return {
                ...state,
                odpExistingStatus: action.data,
                loading: false,
            }
        }
        case GET_SUMMARY_ODP: {
            return {
                ...state,
                summaryODP: action.data,
                loading: false,
            }
        }
        case GET_SUMMARY_LOP: {
            return {
                ...state,
                summaryLop: action.data,
                loading: false,
            }
        }
        case GET_SUMMARY_PROJECT: {
            return {
                ...state,
                summaryProject: action.data,
                loading: false,
            }
        }
        case GET_SUMMARY_ODP_PLAN: {
            return {
                ...state,
                opdSummaryPlan: action.data,
                loading: false,
            }
        }
        case GET_ODP_EXISTING_BY_ID: {
            return {
                ...state,
                odpExistingId: action.data,
                loading: false,
            }
        }
        case GET_LOP_LIST: {
            return {
                ...state,
                lopList: action.data,
                loading: false,
            }
        }
        case GET_ODP_LIST: {
            return {
                ...state,
                odpList: action.data,
                loading: false,
            }
        }
        case GET_SELECT_SEGMEN: {
            return {
                ...state,
                segmenItem: action.data,
                loading: false,
            }
        }
        case GET_ODP_EXISTING: {
            return {
                ...state,
                odpExisting: action.data,
                loading: false,
                count: action.count,
            }
        }
        case GET_SELECT_JENIS: {
            return {
                ...state,
                jenisItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_PROJECT: {
            return {
                ...state,
                projectItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_LOP: {
            return {
                ...state,
                lopItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_REGIONAL: {
            return {
                ...state,
                regionalItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_WITEL: {
            return {
                ...state,
                witelItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_DATEL: {
            return {
                ...state,
                datelItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_STO_BY_PROJECT: {
            return {
                ...state,
                stoByProjectItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_STATUS_BY_JENIS_PROJECT: {
            return {
                ...state,
                statusByJenisItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_STO_BY_LOP: {
            return {
                ...state,
                stoByLopItem: action.data,
                loading: false,
            }
        }
        case GET_SELECT_STATUS_ODP: {
            return {
                ...state,
                statusOdpItem: action.data,
                loading: false,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default PlanningReducer
