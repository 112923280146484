import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./data/AppIndex')))
const EditData = Loadable(lazy(() => import('./data/EditData')))

const preSalesRoute = [
    {
        path: '/presales',
        element: <AppIndex />,
        // auth: authRoles.editor,
    },
    {
        path: '/presales/editData',
        element: <EditData />,
        // auth: authRoles.editor,
    },
]

export default preSalesRoute
