import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./menu/AppIndex')));
const EditData = Loadable(lazy(() => import('./menu/EditValidasiOrder')))

const salesOperationRoute = [
    {
        path: '/salesOperation/inputOrder',
        element: <AppIndex name={'inputOrder'} title={'Input Order'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/salesOperation/validasiOrder',
        element: <AppIndex name={'validasiOrder'} title={'Validasi Order'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/salesOperation/scbeNok',
        element: <AppIndex name={'scbeNok'} title={'SCBE NOK'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/salesOperation/pending',
        element: <AppIndex name={'pending'} title={'Pelanggan Pending'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/salesOperation/mailbox',
        element: <AppIndex name={'mailbox'} title={'RNA/Mailbox'} />,
        // auth: authRoles.editor,
    },
    {
        path: '/salesOperation/koordinatInvalid',
        element: (
            <AppIndex name={'koordinatInvalid'} title={'Koordinat Invalid'} />
        ),
        // auth: authRoles.editor,
    },
    {
        path: '/salesOperation/validasiOrder/editData',
        element: <EditData />,
        // auth: authRoles.editor,
    },
]

export default salesOperationRoute
