import { GET_DASHBOARD } from '../actions/DashboardAction'

const initialState = {
    usiaList: [],
}

const DashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD: {
            return {
                ...state,
                usiaList: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default DashboardReducer
