import { GET_LEVEL, GET_ROLE, GET_SUCCESS } from '../actions/ApprovalAction'

const initialState = {
    dataLevel: null,
    dataRole: null,
    dataSuccess: null,
}

const UsersReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LEVEL: {
            return {
                ...state,
                dataLevel: action.payload,
            }
        }
        case GET_ROLE: {
            return {
                ...state,
                dataRole: action.payload,
            }
        }
        case GET_SUCCESS: {
            return {
                ...state,
                dataSuccess: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default UsersReducer
