import { Api } from '../api'

export const GET_LOG_PENGGUNAAN = 'GET_LOG_PENGGUNAAN'
export const createPerencanaan = (
    form = {
        regional: '',
        witel: '',
        namaProject: '',
        segment: '',
        jenis: '',
        tahunProject: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`/api/planning/project`, form)
            let data = resp.data ? resp.data : null
            /*  dispatch({
                type: GET_STO,
                payload: data,
            }) */
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const updatePerencanaan = (
    id = '',
    form = {
        regional: '',
        witel: '',
        namaProject: '',
        segment: '',
        jenis: '',
        tahunProject: 0,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`/api/planning/project/${id}`, form)
            let data = resp.data ? resp.data : null

            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const listLogPenggunaan = (
    form = {
        page: 1,
        pageLimit: 10,
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/activities`, {
                params: form,
            })
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_LOG_PENGGUNAAN,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
