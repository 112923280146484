import { Api } from '../api'
import { GET_ROLE } from './ApprovalAction'

export const GET_USERS_LIST = 'GET_USERS_LIST'
export const GET_USERS_BY_ID = 'GET_USERS_BY_ID'
export const GET_USERS_APPROVAL = 'GET_USERS_APPROVAL'
export const GET_USERS_REJECT = 'GET_USERS_REJECT'
export const GET_USERS_APPROVAL_ACCEPT = 'GET_USERS_APPROVAL_ACCEPT'
export const ADD_USER_APPROVAL = 'ADD_USER_APPROVAL'
export const UPDATE_USER_APPROVAL = 'UPDATE_USER_APPROVAL'
export const DELETE_USER_APPROVAL = 'DELETE_USER_APPROVAL'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export const getUserList = (limit = 20, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `api/users?page=${page + 1}&pageLimit=${limit}`
            )
            let data = resp.data ? resp.data.data : []
            let count = resp.data ? resp.data.totalData : 0

            dispatch({
                type: GET_USERS_LIST,
                data,
                count,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getUserById = (id = '', status = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/users/${id}`)
            let data = resp.data ? resp.data : null
            if (status !== 'profile') {
                dispatch({
                    type: GET_USERS_BY_ID,
                    payload: data,
                })
            }
            return data
        } catch (err) {
            throw err?.response
        }
    }
}
export const getUsersApproval = (limit = 20, page = 1) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/approval`)
            let data = resp.data?.data ? resp.data?.data : null
            let count = resp.data ? resp.data?.totalData : 0

            dispatch({
                type: GET_USERS_APPROVAL,
                data,
                count,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getProfileImage = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/users/profile/${id}`, {
                responseType: 'blob',
            })
            let data = resp?.data ? resp?.data : null
            dispatch({
                type: UPDATE_PROFILE,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getProfileImageUser = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/users/profile/${id}`, {
                responseType: 'blob',
            })
            let data = resp?.data ? resp?.data : null
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const importImageProfile = (form = null, id = null) => {
    return async (dispatch) => {
        try {
            const formData = new FormData()
            formData.append('', form)
            const resp = await Api.post(`/api/users/profile/${id}`, formData)
            let data = resp.data ? resp.data : null
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getUsersReject = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/approval/reject?userId=${id}`)

            dispatch({
                type: GET_USERS_REJECT,
                resp,
            })
            return {
                data: null,
            }
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const getUsersAccept = (
    form = {
        userId: '',
        role: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`api/approval/accept`, {
                params: {
                    userId: form.userId,
                    role: form.role,
                },
            })
            dispatch({
                type: GET_USERS_APPROVAL_ACCEPT,
                data: resp,
            })
            return {
                data: resp,
            }
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const updateUserApproval = (
    id = '',
    form = {
        fullname: '',
        email: '',
        username: '',
        telegramId: 0,
        password: null,
        role: '',
        level: '',
        regional: '',
        witel: '',
        unit: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.put(`api/users/${id}`, form)
            dispatch({
                type: UPDATE_USER_APPROVAL,
                payload: resp,
            })
            return {
                data: resp,
            }
        } catch (err) {
            throw err?.response?.data
        }
    }
}
export const addUserApproval = (
    form = {
        fullname: '',
        email: '',
        username: '',
        telegramId: 0,
        password: '',
        regional: '',
        witel: '',
        unit: '',
    }
) => {
    return async (dispatch) => {
        try {
            const resp = await Api.post(`api/users/active`, form)
            dispatch({
                type: UPDATE_USER_APPROVAL,
                payload: resp,
            })
            return {
                data: resp,
            }
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const deleteUserApproval = (id = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.delete(`api/users/${id}`)

            return {
                payload: resp,
            }
        } catch (err) {
            throw err?.response?.data
        }
    }
}
