import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import PresalesReducer from './PresalesReducer'
import PlanningReducer from './PlanningReducer'
import UsersReducer from './UsersReducer'
import ApprovalReducer from './ApprovalReducer'
import ValidateOrderReducer from './ValidateOrderReducer'
import DemandDataReducer from './DemandDataReducer'
import PerencanaanReducer from './PerencanaanReducer'
import MapsReducer from './MapsReducer'
import LogReducer from './LogReducer'
import DashboardOrder from './DashboardReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    preSales: PresalesReducer,
    planning: PlanningReducer,
    users: UsersReducer,
    approvals: ApprovalReducer,
    validateOrder: ValidateOrderReducer,
    dashboardOrder: DashboardOrder,
    demandData: DemandDataReducer,
    perencanaan: PerencanaanReducer,
    maps: MapsReducer,
    log: LogReducer,
})

export default RootReducer
