import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from 'app/auth/authRoles'

const AppIndex = Loadable(lazy(() => import('./data/AppIndex')));
const EditData = Loadable(lazy(() => import('./data/EditDataODPExisting')))

const OdpExsitingRoute = [
    {
        path: '/odpExistings',
        element: <AppIndex />,
        // auth: authRoles.editor,
    },
    {
        path: '/odpExistings/editData',
        element: <EditData />,
        // auth: authRoles.editor,
    },
]

export default OdpExsitingRoute
