import { Api } from '../api'

export const GET_DASHBOARD = 'GET_DASHBOARD'
export const getUSIA = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`/api/dashboard/usia-order`)
            let data = resp.data ? resp.data : null
            dispatch({
                type: GET_DASHBOARD,
                payload: data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}
