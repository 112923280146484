import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const AppIndex = Loadable(lazy(() => import('./data/AppIndex')))
const FormUsers = Loadable(lazy(() => import('./data/FormUsers')))
const EditData = Loadable(lazy(() => import('./data/EditData')))
const Profile = Loadable(lazy(() => import('./data/Profile')))
const UserApproval = Loadable(lazy(() => import('./data/UserApproval')))

const UsersRoute = [
    {
        path: '/users',
        element: (
            <AppIndex
                data={{
                    name: 'users',
                    title: 'User',
                }}
            />
        ),
    },
    {
        path: '/users-approval',
        element: (
            <AppIndex
                data={{
                    name: 'users-approval',
                    title: 'Approval',
                }}
            />
        ),
    },
    {
        path: '/users/formUsers',
        element: <FormUsers />,
    },
    {
        path: '/users/editData',
        element: <EditData />,
    },
    {
        path: '/users/profile',
        element: <Profile />,
    },
]

export default UsersRoute
