import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { toast } from 'react-toastify'

const baseUrl = process.env.REACT_APP_API_URL
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    data: null,
    dataRegional: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = async (accessToken, refreshToken) => {
    if (accessToken && refreshToken) {
        await localStorage.setItem('accessToken', accessToken)
        await localStorage.setItem('refreshToken', refreshToken)
        setTimeout(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        }, 1000)
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'OTP': {
            const { data } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                data,
            }
        }
        case 'REGIONAL_DATA': {
            const { dataRegional } = action.payload

            return {
                ...state,
                dataRegional,
            }
        }
        case 'WITEL_DATA': {
            const { data } = action.payload

            return {
                ...state,
                data,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { response } = action.payload

            return {
                ...state,
                response,
            }
        }
        case 'VERIFICATION': {
            const { response } = action.payload

            return {
                ...state,
                response,
            }
        }
        case 'RESET_PASSWORD': {
            const { response } = action.payload

            return {
                ...state,
                response,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    otp: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
    getWitel: () => Promise.resolve(),
    getRegional: () => Promise.resolve(),
    verification: (token = '') => Promise.resolve(),
    forgotPassword: (email = '') => Promise.resolve(),
    resetPassword: (email = '') => Promise.resolve(),
    refreshToken: (refreshToken = '') => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const Api = axios.create({
        baseURL: baseUrl,
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        mode: 'no-cors',
        crossdomain: true,
    })

    const login = async (email, password) => {
        const response = await Api.post('/api/authentications', {
            username: email,
            password: password,
        })

        if (response.status) {
            toast.success('Login Berhasil !', {
                position: toast.POSITION.TOP_CENTER,
            })

            dispatch({
                type: 'LOGIN',
                payload: {
                    user: email,
                },
            })
        }
    }
    const refreshToken = async (refreshToken) => {
        const response = await Api.put('/api/authentications', {
            refreshToken: refreshToken,
        })

        if (response.status) {
            return response
        }
    }

    refreshToken(localStorage.getItem('refreshToken'));
    
    const otp = async (username, otp) => {
        const response = await Api.post('/api/authentications/otp', {
            username: username,
            otp: otp,
        })

        if (response.status) {
            const { accessToken, refreshToken } = response.data.data
            setSession(accessToken, refreshToken)
            toast.success('Login Berhasil !', {
                position: toast.POSITION.TOP_CENTER,
            })

            dispatch({
                type: 'OTP',
                payload: {
                    user: username,
                    otp: otp,
                },
            })
        }
    }
    const getRegional = async () => {
        const response = await Api.get('/api/planning/regional')

        if (response.status) {
            const data = response.data.data

            dispatch({
                type: 'REGIONAL_DATA',
                payload: {
                    dataRegional: data,
                },
            })
        }
    }
    const getWitel = async (regional) => {
        const response = await Api.get('/api/planning/witel', {
            params: {
                regional,
            },
        })

        if (response.status) {
            const data = response.data.data

            dispatch({
                type: 'WITEL_DATA',
                payload: {
                    data,
                },
            })
        }
    }

    const register = async (
        data = {
            fullname: '',
            email: '',
            username: '',
            telegramId: '',
            password: '',
            regional: '',
            witel: '',
            unit: '',
        }
    ) => {
        const response = await Api.post('/api/users', data)
        dispatch({
            type: 'REGISTER',
            payload: {
                response,
            },
        })
        return response
    }
    const forgotPassword = async (
        form = {
            email: '',
        }
    ) => {
        const response = await Api.get('/api/forgot', {
            params: {
                email: form.email,
            },
        })
        dispatch({
            type: 'FORGOT',
            payload: {
                response,
            },
        })
    }
    const resetPassword = async (
        form = {
            token: '',
            password: '',
        }
    ) => {
        const response = await Api.put('/api/forgot', form)
        dispatch({
            type: 'RESET_PASSWORD',
            payload: {
                response,
            },
        })
    }
    const verification = async (token = '') => {
        const response = await Api.get(`/api/verification?token=${token}`)

        dispatch({
            type: 'VERIFICATION',
            payload: {
                response,
            },
        })
        return response
    }

    const logout = async () => {
        const payload = await localStorage.getItem('refreshToken')
        Api.delete(`api/authentications`, payload)
            .then((res) => {
                console.log('response', res.data)
            })
            .catch((err) => {
                console.log(err)
            })

        setSession(null, null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: true,
                        user: null,
                    },
                })

                if (accessToken && isValidToken(accessToken)) {
                    // setSession(accessToken)
                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data
                    // dispatch({
                    //     type: 'INIT',
                    //     payload: {
                    //         isAuthenticated: true,
                    //         user,
                    //     },
                    // })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                otp,
                login,
                logout,
                register,
                verification,
                forgotPassword,
                resetPassword,
                getWitel,
                getRegional,
                refreshToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
